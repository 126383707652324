<!-- 规划导览 -->
<template>
    <div>
        <div class="page_temp page_temp_left">
            <div class="contentBox">
				<item-title name="辖区概述" english="Jurisdiction Overview" />
				<lineBorderBox type='bottom' />
				<div style="padding: .1rem .15rem;">
					<div class="img">
						<lineBorderBox />
						<img :src="countyInfo?countyInfo.imageAdd:''">
					</div>
					<div>
						<div class="info_title">
							<div class="info_dot"><span></span></div>
							<span @click="toggleCunShape">区域简介</span>
						</div>
					</div>
					<div class="cls-button-name">
					    {{ countyInfo?countyInfo.abstracts:'' }}
					</div>
				</div>
			</div>
			<div class="contentBox" style="padding-bottom: 0.2rem;">
				<item-title style="margin-top: 0.2rem;" name="辖区数据统计" english="Jurisdiction Overview" />
				<lineBorderBox type='bottom' />
				<div>
					<!-- <div class="dataList-item">
						<img src="../assets/new_img/a1.png" alt="">
						<div class="item-info">
							<span class="type">辖区面积</span>
							<div>
								<span>78</span>
								<span>（平方公里）</span>
							</div>
						</div>
					</div>
					<div class="dataList-item">
						<img src="../assets/new_img/a2.png" alt="">
						<div class="item-info">
							<span class="type">人口总数</span>
							<div>
								<span>5.2</span>
								<span>（万人）</span>
							</div>
						</div>
					</div>
					<div class="dataList-item">
						<img src="../assets/new_img/a3.png" alt="">
						<div class="item-info">
							<span class="type">社区</span>
							<div>
								<span>1</span>
								<span>（个）</span>
							</div>
						</div>
					</div>
					<div class="dataList-item">
						<img src="../assets/new_img/a4.png" alt="">
						<div class="item-info">
							<span class="type">乡村</span>
							<div>
								<span>19</span>
								<span>（个）</span>
							</div>
						</div>
					</div> -->
                    
                    <div class="dataList-item" v-for="item in v_town_data">
						<img :src="item.imgUrl" alt="">
						<div class="item-info">
							<span class="type">{{item.title}}</span>
							<div>
								<span>{{item.num}}</span>
								<span>（{{item.unit}}）</span>
							</div>
						</div>
					</div>
				</div>
				
			</div>
            
        </div>
		<div class="page_temp page_temp_right">
			<div class="contentBox" style="padding-bottom: 0.2rem;">
				<item-title name="辖区特产资源" english="Source Han Sans CN-Medi" />
				<lineBorderBox type='bottom' />
				<div style="padding: 0 0.08rem;">
					<img-text-list style="margin-top: 0.1rem;box-sizing: border-box;" height="4.3rem" :dataList="resourcesData"></img-text-list>
				</div>
			</div>
            <div class="contentBox" style="padding-bottom: 0.2rem;">
               <item-title name="示范基地" english="Demonstration Base" />
				<lineBorderBox type='bottom' />
				<div class="cls-base">
                    <div class="cls-base-line1">
                        <div class="cls-base-mu">120亩</div> 
                        <div class="cls-base-info1">
                            <div class="cls-base-name" @click="onClickFlyToBase()">小黄姜种植示范基地</div>
                            <div><span class="cls-base-key">种植作物：</span>生姜</div>
                            <div><span class="cls-base-key">基地位置：</span>刘显村</div>
                        </div>
                    </div>
                    <div class="cls-base-line2">亩产1万斤，产值200多万元。村集体经济增收20万元，直接带动20余户脱贫户就业</div>
                    <div class="cls-base-line3"><span @click="onClickOpenWindow(config.URL_XHJ)">查看详情</span></div>
                </div>
			</div>

		</div>
        <!-- 乡镇简要信息弹窗 -->
        <dot-pop :name="tabOption.name" :x="tabOption.x" :y="tabOption.y" :z="tabOption.z" :width="400" :height="250" @closePane="clearPop"
            v-if="messageActive == 4">
            <div class="scrollbar" style="height:100%;overflow: auto;">
            <div class="cls-pane-content" style="font-size: .12rem;color: #fff;line-height: .38rem;margin-top: .2rem;">
                {{ tabOption.introduce }}</div>
                </div>
        </dot-pop>
        <!-- 乡村点位信息 -->
        <div v-for="(item, index) in villageFeatures" :key="index" class="dotPop">
            <dot-pop page="county" :name="item.fieldValues[2]" :x="Number(item.fieldValues[3])" :y="Number(item.fieldValues[4])" :color="dotColor"
                :z="Number(item.fieldValues[0])" type='dot-county'>
                <template v-slot:cunname><span @click="toVillagePage(item.fieldValues[1])" style="cursor:pointer">{{item.fieldValues[2]}}</span></template>
                <img class="dot_icon" src="../assets/new_img/county_icon2.png">
            </dot-pop>
        </div>
        <div class="cls-system-list">
            <img src="../assets/img/sys_rjhjjc.png" @click="onClickOpenWindow(config.URL_RJHJJC)">
            <img src="../assets/img/sys_tsrqga.png" @click="onClickOpenWindow(config.URL_TSRQGA)">
        </div>
    </div>

</template>

<script>
import http from '../util/http.js';
import { getLonLatArray, addPoint, addLine, addPolygonFunction, cleanMarkerListByType, getMakerById } from '../util/drawShape.js';
import messagePop from '../components/message-pop.vue'
import { getBasic, getIconList, getOverView, getTownData } from '@/api/tutorials.js'
import dotPop from "@/components/dot-pop.vue";
import config from '@/config.js'
import itemTitle from '../components/item-title.vue'
import lineBorderBox from '../components/line-border-box.vue'
import imgTextList from "@/components/imgTextList.vue"

var g_is_active_border = false;
var g_border_points = [];     //辖区边界坐标
var g_entity_polygon = null;  //辖区边界线

var g_entity_base_line = null; //基地-线
var g_lonLatArr = [];  //线坐标 
var g_cun_features = [];
var g_cun_show_bln = false;

var g_handler = null;
var g_zhen_code = localStorage.getItem('zhencode');  //'421224107000';
//var g_xqid = config.TENANT_CODE //'420626107204';

export default {
    components: { messagePop, dotPop, itemTitle ,lineBorderBox,imgTextList},
    data() {
        return {
            config: config,
            // 乡镇简要信息弹窗配置
            tabOption: {
                width: 200,
                height: 100,
                name: '乡镇',
                introduce: '',
                x: 0.0,
                y: 0.0,
                z: 0.0
            },
            // 弹窗编号
            messageActive: 0,
            v_boolean_show: true,
            v_if_show_vr: false,
            v_list_vr: [],
            villageList: [],
            path: '/tutorials',//即将要跳转的子页面
            countyInfo: {},
            img_area: require('@/assets/new_img/m1.png'),
            img_count: require('@/assets/new_img/m4.png'),
            img_county: require('@/assets/new_img/m3.png'),
            img_village: require('@/assets/new_img/m2.png'),
            villageFeatures: [],
            dotColor:'#0084ff',
			// 特色资源数据
            resourcesData: [],
            
            v_town_data:[]
        }
    },
    mounted() {
        let pMountedTimer = window.setInterval(() => {
            if (window.parentMounted) {
                window.clearInterval(pMountedTimer)
                this.initData()
                let countyData = JSON.parse(sessionStorage.getItem('token'))
                if (countyData.tenant) {
                    this.villageList = countyData.tenant.child;
                }
            }
        }, 500)
    },
    methods: {
        // 初始化数据
        initData: function () {
            this.initMap()

            this.requestZhen();
            this.requestCunList();
            this.requestAllCun();
            this.getCountyInfo();
            this.requestBaseInfo();
            //this.$parent.show2DMap();
			this.$parent.delMap();
			this.getIconList()

        },
        // 获取县城概况信息
        getCountyInfo() {
            let id = localStorage.getItem('tenantIdVown')
            getOverView(id).then(res => {
                this.countyInfo = res.data
            })
            //动态请求镇统计信息
            getTownData().then(res =>{
                if(res.code == 200){
                    for(var item of res.rows){
                        this.v_town_data.push({
                            title: item.title, 
                            imgUrl: item.markerUrl,
                            unit: item.unit,
                            num: item.placeArea
                        });
                    }
                }
            });
        },
		// 获取辖区特产资源
		getIconList: function () {
			let data = {
				pageNum: 1,
				type: 3,
				pageSize:14,
				county:true
			}
			getIconList(data).then(res => {
				this.resourcesData = res.rows
			})
		},
        initMap: function () {
            getBasic().then(res => {
                //边界坐标字符串
                g_border_points = res.data.initialFeature.geometry.pointArr;

            })
        },

        //显示与隐藏辖区边界线
        toggleXqBorder: function () {
            if (g_is_active_border) {
                if (g_entity_polygon) {
                    viewer.entities.remove(g_entity_polygon);
                    g_entity_polygon = null;
                    console.log('--隐藏辖区边线');
                }
            } else {
                if (g_border_points.length > 0) {//如果已经缓存坐标串，直接画面，不需请求服务器
                    g_entity_polygon = this.drawXqBorderPolygon(g_border_points);
                    // viewer.flyTo(g_entity_polygon, {
                    //     duration: 5,
                    //     offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0))
                    // });
                    console.log('--显示辖区边线', g_border_points.length);
                }
            }
            g_is_active_border = !g_is_active_border;
        },

        //画边界线
        drawXqBorderPolygon: function (pointArr) {
            return viewer.entities.add({
                polyline: {
                    clampToGround: true,
                    positions: Cesium.Cartesian3.fromDegreesArray(pointArr),
                    material: new Cesium.PolylineGlowMaterialProperty({
                        glowPower: 0.5,
                        color: Cesium.Color.ORANGERED,
                    }),//Cesium.Color.RED, //new Cesium.Color.fromCssColorString("#FF0000"), //.withAlpha(.2),                     
                    width: 4,
                    clampToGround: true
                }
            });
        },

        // 关闭弹窗
        clearPop: function () {
            this.messageActive = 0
        },

        requestZhen() {
            var _str_filter = "code= '" +g_zhen_code + "' ";
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p420000:xzqh_zhen"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["smid", "code", "name"]
                },
                "maxFeatures": 100
            }
            http.requestIServer(_sqlParameter, (result) => {
                console.log('-- 回调--', result.totalCount);
                if (result.features.length == 0) {
                    return;
                }
                // var _regions = getLonLatArray(result.features[0].geometry.points);
                //this.drawXqBorderPolygon(_regions);    
                // addLine('xqline', '0', '', _regions, null, { lineColor: '#ffc748' });   
                var minLat = 90;
                var minLon = 180;
                var maxLat = 0;
                var maxLon = 0;
                var _points = result.features[0].geometry.points;
                for (var i = 0; i < _points.length; i++) {
                    var _point = _points[i];
                    var lat = _point.y;
                    var lon = _point.x;
                    minLat = Math.min(minLat, lat);
                    minLon = Math.min(minLon, lon);
                    maxLat = Math.max(maxLat, lat);
                    maxLon = Math.max(maxLon, lon);
                }
                console.log('--镇坐标范围--', minLon, minLat, maxLon, maxLat);
                var _config = { width: 5 };
                viewer.camera.flyTo({
                    destination: Cesium.Rectangle.fromDegrees(minLon, minLat, maxLon, maxLat) //west, south, east, north)
                });
            });
        },

        requestCunList: function () {
            cleanMarkerListByType('cun');console.log(g_zhen_code,'--------');
            var _str_filter = "code like '" + g_zhen_code.substring(0, 9) + "%' ";
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p420000:xzqh_cun"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["smid", "code", "name", "jyjs"],
                    "orderby": 'code'
                },
                "maxFeatures": 100
            }
            http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
                console.log('--村列表回调1--', result.totalCount);
                g_cun_features = result.features;
                this.toggleCunShape();
            });
        },

        toggleCunShape(){
            if(g_cun_show_bln){
                cleanMarkerListByType('cun');
            }else{
                if (g_cun_features.length > 0) {
                    var colorArr = ['#2977ff', '#73c3fe', '#6fecff', '#dafae2', '#9ff4dd', '#32c599', '#fdee21', '#facb59', '#c6843d', '#dc8015', '#f18990',
                     '#ffa500', '#808000', '#ff7f50', '#8a2be2', '#66cccc', '#ccff66', '#ffcc99', '#cc9999', '#ff6666', '#990033', '#cccc99'];
                    for (var i = 0; i < g_cun_features.length; i++) {
                        var _feature = g_cun_features[i];
                        var _lonLatArr = getLonLatArray(_feature.geometry.points);
                        var _item = {
                            id: i,
                            code: _feature.fieldValues[1],
                            name: _feature.fieldValues[2],
                            introduce: _feature.fieldValues[3],
                            shape: _lonLatArr
                        };
                        var config = { polygonFillColor: colorArr[i % colorArr.length], alpha: 0.6 };  //, classificationType: 'S3M_TILE'
                        addPolygonFunction('cun', i, _item.name, _item.shape, _item.introduce, config);
                    }
                    this.addClickEvent();
                }
            }
            g_cun_show_bln = !g_cun_show_bln;
        },
        hideCunShape(){
            cleanMarkerListByType('cun');
            g_cun_show_bln = false;
        },

        addClickEvent: function () {
            //单击选中事件
            if (g_handler) {
                g_handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
            }else{
                g_handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            }   
            g_handler.setInputAction((movement) => {
                var position = viewer.scene.pickPosition(movement.position);
                var cartographic = Cesium.Cartographic.fromCartesian(position);
                var longitude = Cesium.Math.toDegrees(cartographic.longitude);
                var latitude = Cesium.Math.toDegrees(cartographic.latitude);
                var height = cartographic.height;
                viewer.entities.removeById('focus-line');
                this.messageActive = 0;
                var pick = viewer.scene.pick(movement.position);
                if (Cesium.defined(pick)) {
                    if (pick.id) {
                        //选中
                        var _entity = pick.id;
                        console.log('单击选中', _entity);
                        if (_entity.ptype == 'cun') {
                            this.tabOption.name = _entity.data.mc;
                            this.tabOption.introduce = _entity.data.popHtml;
                            this.tabOption.x = longitude;
                            this.tabOption.y = latitude;
                            this.tabOption.z = 20;//height;
                            this.messageActive = 4;

                            viewer.entities.add({
                                id: 'focus-line',
                                polyline: {
                                    clampToGround: true,
                                    positions: _entity.polygon.hierarchy.getValue().positions, //Cesium.Cartesian3.fromDegreesArray(pointArr),
                                    //material: Cesium.Color.RED, //new Cesium.Color.fromCssColorString("#FF0000"), //.withAlpha(.2),                     
                                    material: new Cesium.PolylineGlowMaterialProperty({
                                        glowPower: 0.5,
                                        color: Cesium.Color.RED, //ORANGERED,
                                    }),
                                    width: 8
                                }
                            });
                        } else if (_entity.ptype == 'cwh') {
                            this.toVillagePage(_entity.data.id);
                        }
                    }
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        },

        //
        requestAllCun() {
            var _str_filter = "code like '" + g_zhen_code.substring(0, 6) + "%' ";
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p420000:xzqh_cun"],
                "queryParameter": {
                    "attributeFilter":  _str_filter,
                    "fields": ["smid", "code", "name", "cwx", "cwy"],
                    "orderby": 'code'
                },
                "maxFeatures": 1000
            }
            http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
                console.log('--村列表回调2--', result.totalCount);

                for (var _feature of result.features) {
                    var _config = { z: this.$parent.getZ(), width: 20, height: 25, distanceDisplayCondition: 500000 };
                    var _shape = [_feature.fieldValues[3], _feature.fieldValues[4]]
                    // addPoint('cwh', _feature.fieldValues[1], null, _shape, '', _config);
                }
                this.villageFeatures = result.features.reverse()
            });
        },
        toVillagePage(code) {
            this.villageList.forEach(item => {
                if (code == item.code) {
                    localStorage.setItem('tenantName', item.name)
                    localStorage.setItem('tenantCode', item.code)
                    localStorage.setItem('tenantId', item.id);
                    this.$parent.requestMapConfig();//更新父组件的地图配置
                    this.$router.push({ path: this.path });
                    return;
                }
            })
            // this.$router.push({ path: this.path });
        },

        requestBaseInfo: function () {
            cleanMarkerListByType('base-line');
            var _str_filter = "mc  = '小黄姜种植示范基地' ";
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p420700:t02_xcjyzt_m"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["smid", "id", "mc"]
                },
                "maxFeatures": 1
            }
            http.requestIServerWithPage(_sqlParameter, 0, 1, (result) => {
                console.log('--基地回调--', result.totalCount);
                if (result.features.length > 0) {
                    for (var i = 0; i < result.features.length; i++) {
                        var _feature = result.features[i];
                        g_lonLatArr = getLonLatArray(_feature.geometry.points);
                    }
                }
            });
        },

        onClickFlyToBase(){
            this.hideCunShape();
            if(g_entity_base_line){
                viewer.entities.remove(g_entity_base_line);
                g_entity_base_line = null;
            }else{
                g_entity_base_line = addLine('base', 'line', '', g_lonLatArr);
                viewer.flyTo(g_entity_base_line, {
                    offset: {
                        heading: 0.0,
                        pitch: -1.5,
                        range: 100.0
                    }
                });
            }
        },
        onClickOpenWindow(url){
            window.open(url, '_blank');
        }
    },

    destroyed() {
        cleanMarkerListByType('cun');
        cleanMarkerListByType('xqline');
        cleanMarkerListByType('cwh');
        if(g_entity_base_line){
            viewer.entities.remove(g_entity_base_line);
            g_entity_base_line = null;
        }
        viewer.entities.remove(g_entity_polygon);
        viewer.entities.removeById('focus-line');
        g_entity_polygon = null;
        g_cun_show_bln = false;
        if (g_handler) {
            g_handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        }

        g_handler = g_handler && g_handler.destroy();
    }
}
</script>
<style>
.cls-pane-content {
    font-size: 12px;
    color: #bbb;
}
</style>
<style lang="scss" scoped>
.contentBox {
	box-sizing: border-box;
	.img{
		position: relative;
	}
}

.cls-button-name {
    line-height: .24rem;
    box-sizing: border-box;
    overflow: auto;
	font-size: 0.14rem;
	text-indent: 2em;
}

.cls-button-name::-webkit-scrollbar {
    width: .04rem;
}

.cls-button-name::-webkit-scrollbar-thumb {
    background-color: #ddd;
    width: .04rem;
    border-radius: .05rem;
}

.img {
    width: 100%;
    overflow: hidden;
    height: 2rem;
    box-sizing: border-box;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
    img {
        width: 96%;
        height: 96%;
    }
}

.img-item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgba(225, 225, 225, 0.3);
    height: .68rem;
    border-radius: .04rem;
    margin-top: .15rem;
    box-sizing: border-box;
    overflow: hidden;
    padding-left: .24rem;

    img {
        margin-right: .16rem;
        width: .38rem;
        height: .38rem;
        border-radius: 50%;
    }

    div {
        font-size: .14rem;

        span {
            font-size: .16rem;
            margin: 0 .16rem;
            color: #fff000;
        }
    }
}

.img-item-first {
    margin-top: .25rem;
}

.img-box {
    display: flex;
    justify-content: space-between;

    .img-item {
        width: 48%;

        div {
            display: flex;
            flex-direction: column;

            div {
                display: flex;
                flex-direction: row;

                span {
                    margin-left: 0;
                }
            }
        }
    }
}
.dot_icon{
    width: 110px;
	transform: translate(60px,-46px);
}
.module-box{
    width: 100%;
	height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: .2rem;
    .module-item{
        width: 1.26rem;
        height: 1.26rem;
        background-image: url(../assets/img/server/a2.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .name{
            margin: .06rem 0;
        }
        .title{
            font-size: .14rem;
            color: #ffc748;
            span{
                font-size: .24rem;
            }
        }
    }
    .module-item:hover{
        background-image: url(../assets/img/server/a1.png);
        .title{
            color: #fff;
            span{
                color: #22f2ff;
            }
        }
    }
}
.info_title{
	display: flex;
	align-items: center;
	.info_dot{
		width: 0.12rem;
		height: 0.12rem;
		margin: 0.1rem 0;
		border: 0.01rem solid #069999;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		span{
			display: block;
			width: 0.06rem;
			height: 0.06rem;
			box-shadow: 0 0 0.03rem 0.01rem #18F5F5;
			background-color: #18F5F5;
		}
	}
}
.info_title>span{
	font-size: 0.14rem;
	font-weight: 500;
	color: #00FFFF;
	line-height: 0.2rem;
	margin-left: 0.1rem;
}
.dataList-item{
	height: 0.63rem;
	background: rgba(3,32,77,0.16);
	border: 0.01rem solid;
	margin-top: 0.1rem;
	border-image: linear-gradient(90deg, rgba(24, 142, 245, 1), rgba(24, 142, 245, 0)) 1 1;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	img{
		margin: 0 0.08rem;
		width: 0.4rem;
		height: 0.4rem;
		box-sizing: border-box;
	}
	.item-info{
		display: flex;
		flex-direction: column;
		font-size: 0.14rem;
		width: 100%;
		.type{
			background: linear-gradient(90deg, rgba(25,152,255,0.2) 0%, rgba(24,142,245,0) 100%);
			font-size: 0.14rem;
			font-family: Source Han Sans CN-Medium, Source Han Sans CN;
			font-weight: 500;
			color: #C4DEF5;
			line-height: 0.18rem;
			padding-left: 0.08rem;
		}
		div{
			display: flex;
			span:first-child{
				width: 60%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 0.32rem;
				font-weight: bold;
				color: #19F5F5;
				line-height: 0.36rem;
				text-shadow: 0px 0px 0.08rem #3385FF;
			}
			span:last-child{
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;
				font-size: 0.1rem;
				font-family: Source Han Sans CN-Medium, Source Han Sans CN;
				font-weight: 500;
				color: rgba(255,255,255,0.6);
				line-height: 0.12rem;
			}
		}
	}
}
.cls-base{
    padding: 0 0.08rem;
    .cls-base-line1{
        display: flex;
        margin-top: .1rem;
        .cls-base-mu{
            font-size: 0.2rem;
            border: 0.15rem solid #3385FF;
            width: .8rem;
            line-height: .8rem;
            height: .8rem;
            border-radius: 50%;
            text-align: center;
        }
        .cls-base-info1{
            padding: .2rem;
            line-height: .25rem;
            .cls-base-name{
                font-size: .2rem;
            }
            .cls-base-key{
                color: #bbb;
            }
        }

    }
    .cls-base-line2{
        color: #bbb;
    }
    .cls-base-line3{
        color: #19F5F5;
        text-align: right;
        cursor: pointer;
    }

}

.cls-system-list{
    position:absolute;
    bottom:20px;
    right:4.5rem;
    z-index:500;
    
    img{
        margin:5px;
        width: 0.8rem;
        cursor: pointer;
    }
}
</style>